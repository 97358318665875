<template>
  <ion-page>

    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!isLoading">

      <ion-row class="sideMargins" style="margin-top: 40px;">

        <ion-col size="12">
          <ion-label class="title" style="margin-left: 17px;">Bestätigen</ion-label>
        </ion-col>
        <ion-col size="12" v-if="errorWhileConfirming">
          <ion-card>
            <p>Etwas ist schiefgelaufen. Überprüfe deinen Code und versuche es nochmal.</p>
            <small>{{ errorMsg }}</small>
          </ion-card>
        </ion-col>
        <ion-col size="12" v-if="errorWhileCreating">
          <ion-card>
            <p>Etwas ist schiefgelaufen.</p>
            <small>{{ errorCreatingMsg }}</small>
          </ion-card>
        </ion-col>
        <ion-col size="12">
          <p style="padding-left: 17px;">
            <small>Wir haben dir einen 5 stelligen Code auf deine E-Mail zugeschickt. (Schau bitte auch im Spam Ordner)</small>
          </p>
        </ion-col>
        <ion-col size="12" v-if="newCodeIsPossible">
          <p style="padding-left: 17px;">
            <small>Keinen Code bekommen?</small>
            <ion-button size="small" @click="sendNewCode">Neuen Code anfordern</ion-button>
          </p>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Code">Code *</ion-label>
            <ion-input v-model="code" @keyup="checkCodeLength"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12" style="margin-top: 10px;">
          <ion-button expand="block" style="margin-left: 17px;" @click="confirmUser()" :disabled="!codeIsValid">Jetzt bestätigen</ion-button>
        </ion-col>
        <ion-col size="12" style="margin-top: 35px;">
          <ion-button expand="block" style="margin-left: 17px;" color="light" router-link="/login">Abbrechen</ion-button>
        </ion-col>
      </ion-row>

    </ion-content>

    <ion-content v-if="isLoading" class="spinnerCenter">
      <ion-spinner name="dots"></ion-spinner>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonTitle,
  IonCard,
  IonItem,
  IonInput,
  IonSpinner,
  toastController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Confirm',
  data () {
    return {
      code: null,
      isLoading: false,
      errorMsg: null,
      errorWhileConfirming: false,
      errorWhileCreating: false,
      errorCreatingMsg: null,
      codeIsValid: false,
      newCodeIsPossible: false,
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonCard
  },
  created() {

      // check for userToken
      if (localStorage.userToken) {
        this.newCodeIsPossible = true;
      }

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    checkCodeLength() {
        if (this.code !== "" && this.code.length == 5) {
            this.codeIsValid = true;
        }
    },
    clearUserCookies(){
      localStorage.clear();
    },
    confirmUser() {

      this.isLoading = true;

      const formData = new FormData();
      formData.append("code", this.code);
      axios.post(process.env.VUE_APP_API_URL + '/confirm-user', formData).then(result => {
          if (result.data.status) {
              this.$router.push({ path: '/register/confirm/success'});
          }
      })
      .catch(error => {
          this.errorWhileConfirming = true;
          this.errorMsg = error.message;
      })
      .finally(() => {
        this.isLoading = false;
      });

    },
    sendNewCode() {
      axios.post(process.env.VUE_APP_API_URL + "/resend-code", {}, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
      .then(response => {
          if (!response.data.status) {
              this.errorWhileCreating = true;
              this.errorCreatingMsg = response.data.message;
          } else {
            this.openToast('Neuer Code wurde dir auf deine Mail Adresse geschickt.', 5000);
          }
      })
    }

  }
});

</script>

<style scoped>

ion-spinner {
  display: block;
  margin: auto;
  margin-top: 50%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.rounded-image {
  border-radius: 50%;
}

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 25%;
}

.spinnerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bottomMargins {
  margin-bottom: 25px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.price {
  font-size: 20px;
  font-weight: bold;
}

.roundedCorners {
  border-radius: 10px;
}

</style>