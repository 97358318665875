
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonMenuButton,
  IonPage,
  IonRow,
  IonCol,
  IonLabel,
  IonTitle,
  IonCard,
  IonItem,
  IonInput,
  IonSpinner,
  toastController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Confirm',
  data () {
    return {
      code: null,
      isLoading: false,
      errorMsg: null,
      errorWhileConfirming: false,
      errorWhileCreating: false,
      errorCreatingMsg: null,
      codeIsValid: false,
      newCodeIsPossible: false,
    }
  },
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonLabel,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonTitle,
    IonItem,
    IonInput,
    IonSpinner,
    IonCard
  },
  created() {

      // check for userToken
      if (localStorage.userToken) {
        this.newCodeIsPossible = true;
      }

  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    checkCodeLength() {
        if (this.code !== "" && this.code.length == 5) {
            this.codeIsValid = true;
        }
    },
    clearUserCookies(){
      localStorage.clear();
    },
    confirmUser() {

      this.isLoading = true;

      const formData = new FormData();
      formData.append("code", this.code);
      axios.post(process.env.VUE_APP_API_URL + '/confirm-user', formData).then(result => {
          if (result.data.status) {
              this.$router.push({ path: '/register/confirm/success'});
          }
      })
      .catch(error => {
          this.errorWhileConfirming = true;
          this.errorMsg = error.message;
      })
      .finally(() => {
        this.isLoading = false;
      });

    },
    sendNewCode() {
      axios.post(process.env.VUE_APP_API_URL + "/resend-code", {}, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
      .then(response => {
          if (!response.data.status) {
              this.errorWhileCreating = true;
              this.errorCreatingMsg = response.data.message;
          } else {
            this.openToast('Neuer Code wurde dir auf deine Mail Adresse geschickt.', 5000);
          }
      })
    }

  }
});

